export default [
  {
    path: 'history',
    component: () => import('./HistoryPage.vue'),
    children: [
      {
        path: '',
        redirect: {
          name: 'history.point'
        }
      },
      {
        path: 'point',
        name: 'history.point',
        meta: {
          title: 'Riwayat Point'
        },
        component: () => import('./point/HistoryPointPage.vue')
      },
      {
        path: 'member',
        name: 'history.member',
        meta: {
          title: 'Riwayat Member'
        },
        component: () => import('./member/HistoryMemberPage.vue')
      },
      {
        path: 'redeem',
        name: 'history.redeem',
        meta: {
          title: 'Riwayat Redeem'
        },
        component: () => import('./redeem/HistoryRedeemPage.vue')
      }
    ]
  }
]

export default [
  {
    path: 'search-member',
    name: 'search-member',
    meta: {
      title: 'Cari Member'
    },
    component: () => import('./search/SearchMemberPage.vue')
  },
  {
    path: 'search-member/:uuid',
    name: 'search-member.detail',
    meta: {
      title: 'Cari Member'
    },
    component: () => import('./detail/SearchMemberDetailPage.vue')
  }
]

export default [
  {
    path: 'add-point',
    name: 'add-point',
    meta: {
      title: 'Tambah Point'
    },
    component: () => import('./search/AddPointSearchPage.vue')
  },
  {
    path: 'add-point/:uuid',
    name: 'add-point.detail',
    meta: {
      title: 'Tambah Point'
    },
    component: () => import('./detail/AddPointSearchDetailPage.vue')
  }
]

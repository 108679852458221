export default [
  {
    path: 'add-member',
    name: 'add-member',
    meta: {
      title: 'Tambah Member'
    },
    component: () => import('./input/AddMemberInputPage.vue')
  },
  {
    path: 'add-member/add',
    name: 'add-member.add',
    meta: {
      title: 'Tambah Member'
    },
    component: () => import('./form/AddMemberFormPage.vue')
  }
]

import { createRouter, createWebHistory } from 'vue-router'

import Login from '@/pages/login/routes'
import Home from '@/pages/home/routes'
import SearchMember from '@/pages/search-member/routes'
import AddMember from '@/pages/add-member/routes'
import AddPoint from '@/pages/add-point/routes'
import History from '@/pages/history/routes'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/app',
      component: () => import('@/components/layouts/MainLayout.vue'),
      children: [Home, ...SearchMember, ...AddMember, ...AddPoint, ...History]
    },
    {
      path: '/auth',
      component: () => import('@/components/layouts/AuthLayout.vue'),
      children: [Login]
    },
    { path: '/:pathMatch(.*)*', redirect: '/app/home' }
  ]
})

router.beforeEach((to, _from, next) => {
  // Get the page title from the route meta data that we have defined
  // See further down below for how we setup this data
  const title = to.meta.title
  // If the route has a title, set it as the page title of the document/page
  if (title) {
    const selectedProject = import.meta.env.VITE_PROJECT
    const parentTitle =
      selectedProject === '707' ? '707 CSWEB | ' : 'Atmos CSWEB | '

    document.title = (parentTitle + title) as any
  }
  // Continue resolving the route
  next()
})

export default router

<template>
  <div class="global-layout">
    <router-view></router-view>
  </div>
</template>

<script lang="ts" setup>
import { useFavicon } from '@vueuse/core'

const selectedProject = computed<'707' | 'Atmos'>(() => {
  return import.meta.env.VITE_PROJECT || '707'
})

const favicon = computed(() => {
  return selectedProject.value === '707' ? '707-ico.png' : 'atmos-ico.png'
})

useFavicon(favicon.value, {
  baseUrl: '/',
  rel: 'icon'
})
</script>

<style lang="scss" scoped>
.global-layout {
  @apply flex min-h-screen;
  background-image: url('/pattern.png');
  background-position: bottom;
  background-repeat: repeat-x;
}
</style>
